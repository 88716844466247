<template>
  <div class="page">
    <div class="content-box section">
      <div class="header">
        <div class="title">手续费设置</div>
        <el-button class="save-btn" type="primary">保存</el-button>
      </div>
      <easy-card title="入账设置">
        <el-table
            :data="entrySetData"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column label="渠道" prop="channel"></el-table-column>
          <el-table-column label="值" prop="value"></el-table-column>
        </el-table>
      </easy-card>
      <easy-card title="提现设置">
        <div class="add">
          <el-form
              ref="model"
              :inline="true"
              :model="model"
              class="demo-form-inline"
              size="medium"
          >
            <el-row>
              <el-form-item label="最低提现额：" prop="minWithdrawal">
                <el-input
                    v-model="model.minWithdrawal"
                    placeholder="请输入最低提现额"
                >
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="最高体现额：" prop="maxWithdrawal">
                <el-input
                    v-model="model.maxWithdrawal"
                    placeholder="请输入最高提现额"
                >
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-row>
          </el-form>
          <el-button class="add-btn" type="primary" @click="addInterval"
          >添加区间
          </el-button
          >
        </div>
        <el-table
            :data="withdrawalSetData"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column label="区间"></el-table-column>
          <el-table-column label="类型" prop="type"></el-table-column>
          <el-table-column label="值" prop="commissionMoney"></el-table-column>
          <el-table-column label="是否审核" prop="isVerify"></el-table-column>
          <el-table-column label="操作" prop="value">
            <template slot-scope="scope">
              <el-button
                  class="common-text"
                  size="small"
                  type="text"
                  @click="goEdit(scope.row.id)"
              >编辑
              </el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </easy-card>
    </div>
    <el-dialog
        :append-to-body="true"
        :before-close="intervalDialogBeforeClosed"
        :visible.sync="intervalDialogFormVisible"
        class="common-dialog"
        title="添加区间"
        width="700px"
    >
      <el-form
          ref="form"
          :inline="true"
          :model="form"
          :rules="rules"
          label-width="100px"
          size="medium"
      >
        <el-row>
          <el-form-item label="类型：" prop="type">
            <el-select v-model="form.type" style="width: 360px">
              <el-option label="定额" value="1"></el-option>
              <el-option label="定率" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-row>

        <el-row>
          <el-form-item label="提现区间：" prop="minWithdrawal">
            <el-input
                v-model="form.minWithdrawal"
                placeholder="请输入最低提现额"
                style="width: 150px"
            ></el-input>
            <span style="margin: 0 20px">至</span>
            <el-input
                v-model="form.maxWithdrawal"
                placeholder="请输入最高提现额"
                style="width: 150px"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="手续费金额：" prop="commissionMoney">
            <el-input
                v-model="form.commissionMoney"
                placeholder="请输入手续费金额"
                style="width: 360px"
            >
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="是否审核：" prop="isVerify">
            <el-radio-group v-model="form.isVerify">
              <el-radio label="0">审核</el-radio>
              <el-radio label="1">不审核</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelAdd">取 消</el-button>
        <el-button type="primary" @click="saveAddOrEdit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "set_commission",
  data() {
    return {
      entrySetData: [
        {channel: "微信手续费", value: "0.1%"},
        {channel: "支付宝手续费", value: "1.5%"},
        {channel: "银联手续费", value: "0.1%"},
      ],
      model: {
        minWithdrawal: 0,
        maxWithdrawal: 1500,
      },
      withdrawalSetData: [],
      intervalDialogFormVisible: false,
      form: {
        type: "1",
        minWithdrawal: "",
        maxWithdrawal: "",
        commissionMoney: "",
        isVerify: "0",
      },
      rules: {},
    };
  },
  methods: {
    //添加区间弹窗
    addInterval() {
      this.intervalDialogFormVisible = true;
    },
    //取消添加
    cancelAdd() {
      this.intervalDialogFormVisible = false;
      this.$refs.form.resetFields();
      this.form.minWithdrawal = "";
    },
    //关闭弹窗清空表单
    intervalDialogBeforeClosed(done) {
      this.$refs.form.resetFields();
      this.form.minWithdrawal = "";
      done();
    },
    //保存添加区间
    saveAddOrEdit() {
    },
    //编辑
    goEdit() {
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 12px;
  }

  .save-btn {
    width: 127px;
    background: #0768FD;
    border-radius: 4px 4px 4px 4px;
    border-color: #0768FD;
  }
}

.add {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .add-btn {
    width: 127px;
    height: 41px;
    background: #0768FD;
    border-radius: 4px 4px 4px 4px;
    border-color: #0768FD;
    margin-top: -6px;
  }
}

.form-item {
  margin: 0 auto;
}
</style>
